<template>
    <div class="main">
        <router-view :key="$route.fullPath" />
        <van-tabbar v-model="active" class="bottom-bar" route :border="false" v-show="$route.meta.showTabbar">
            <van-tabbar-item replace to="/">
                <span>藏品</span>
                <template #icon="props">
                    <van-icon :name="props.active ? 'wap-home' : 'wap-home-o'" :color="props.active ? '#fff' : '#9f9f9f'" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/shop">
                <span>生活</span>
                <template #icon="props">
                    <van-icon :name="props.active ? 'bag' : 'bag-o'" :color="props.active ? '#fff' : '#9f9f9f'" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/market">
                <span>市场</span>
                <template #icon="props">
                    <van-icon :name="props.active ? 'cart-circle' : 'cart-circle-o'" :color="props.active ? '#fff' : '#9f9f9f'" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/notice">
                <span>公告</span>
                <template #icon="props">
                    <van-icon :name="props.active ? 'chat' : 'chat-o'" :color="props.active ? '#fff' : '#9f9f9f'" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/member">
                <span>我的</span>
                <template #icon="props">
                    <van-icon :name="props.active ? 'manager' : 'manager-o'" :color="props.active ? '#fff' : '#9f9f9f'" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    name: "App",
    components: {},
    data() {
        return {
            active: 0,
            icon: [
                {
                    active: "/images/tabbar/home_activity.png",
                    inactive: "/images/tabbar/home.png",
                },
                {
                    inactive: "/images/tabbar/market.png",
                    active: "/images/tabbar/market_activity.png",
                },
                {
                    inactive: "/images/tabbar/message.png",
                    active: "/images/tabbar/message_a.png",
                },
                {
                    inactive: "/images/tabbar/user.png",
                    active: "/images/tabbar/user_a.png",
                },
            ],
        };
    },
    // 禁止web端屏幕缩放
    created() {
        window.addEventListener(
            "mousewheel",
            function (event) {
                if (event.ctrlKey === true || event.metaKey) {
                    event.preventDefault();
                }
            },
            { passive: false }
        );
    },
    watch: {
        $route() {
            this.handleActive();
        },
    },
    methods: {
        handleActive() {
            let path = this.$route.path;
            if (path.indexOf("/market") !== -1) {
                this.active = 1;
            } else if (path.indexOf("/notice") !== -1) {
                this.active = 2;
            } else if (path.indexOf("/member") !== -1) {
                this.active = 3;
            } else {
                this.active = 0;
            }
        },

        // 禁止缩放(浏览器中)
        forbidZoom() {
            document.addEventListener(
                "keydown",
                function (event) {
                    if (
                        (event.ctrlKey === true || event.metaKey === true) &&
                        (event.keyCode === 61 ||
                            event.keyCode === 107 ||
                            event.keyCode === 173 ||
                            event.keyCode === 109 ||
                            event.keyCode === 187 ||
                            event.keyCode === 189)
                    ) {
                        event.preventDefault();
                    }
                },
                false
            );
            document.addEventListener(
                "mousewheel",
                function (e) {
                    e = e || window.event;
                    // @ts-ignore
                    if ((e.wheelDelta && event.ctrlKey) || e.detail) {
                        event.preventDefault();
                    }
                },
                {
                    capture: false,
                    passive: false,
                }
            );
        },

        // 禁止双指放大（手机端）
        forbidZoomClick() {
            document.documentElement.addEventListener(
                "touchstart",
                function (event) {
                    if (event.touches.length > 1) {
                        event.preventDefault();
                    }
                },
                false
            );
        },

        // 禁止双击屏幕放大（手机端）
        forbidZoomDoubleClick() {
            var lastTouchEnd = 0;
            document.documentElement.addEventListener(
                "touchend",
                function (event) {
                    var now = Date.now();
                    if (now - lastTouchEnd <= 300) {
                        event.preventDefault();
                    }
                    lastTouchEnd = now;
                },
                false
            );
        },
    },
    mounted() {
        this.forbidZoom(); // dom挂载完成后，及禁止浏览器缩放
        this.forbidZoomClick();
        this.forbidZoomDoubleClick();
    },
};
</script>
<style lang="less" scoped>
.van-tabbar {
    background-color: #17181c;
    // border-radius: 15px 15px 0 0;
    border-top: 2px solid;
    // border-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ece99b 50%, rgba(255, 255, 255, 0) 99%) 2 2 2 2;
    border-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            #fff 50%,
            rgba(255, 255, 255, 0) 99%
        )
        2 2 2 2;
}
.van-tabbar-item--active {
    background-color: #17181c;
}
#app {
    height: 100%;
    margin-top: 0;
}
.bottom-bar {
    padding: 15px 0 15px;
    img {
        height: 20px;
    }
    span {
        padding-top: 5px;
        display: block;
        color: #6f6f6f;
    }
}
.bottom-bar .van-tabbar-item--active {
    span {
        // color: rgb(236, 233, 155);
        // background: linear-gradient(to right, #182df2, #9405ed);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
        color: #fff;
    }
}
[class*="van-hairline"]::after {
    border: none;
}
</style>
