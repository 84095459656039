const routerConfig = {
    routes: [
        {
            path: '/',
            name: 'index',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/home/home')
            ,
            meta: {
                title: '首页',
                showTabbar: true

            }
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/login/login')
            ,
            meta: {
                title: '登录',

            }
        },
        {
            path: '/member',
            name: 'member',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/member')
            ,
            meta: {
                title: '我的',
                showTabbar: true

            }
        },
        {
            path: '/member/wallet',
            name: 'wallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/wallet')
            ,
            meta: {
                title: '我的钱包',
                showTabbar: false

            }
        },
        {
            path: '/member/generate-hf-wallet',
            name: 'generateHfWallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/generate-hf-wallet')
            ,
            meta: {
                title: '汇付钱包开户',

            }
        },
        {
            path: '/member/generate-sd-wallet',
            name: 'generateSdWallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/generate-sd-wallet')
            ,
            meta: {
                title: '杉德钱包开户',

            }
        },
        {
            path: '/product',
            name: 'product_detail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/goods/goods-info'),
            meta: {
                title: '详情',
            }
        },
        {
            path: '/mygoods-list',
            name: 'mygoods-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-list'),
            meta: {
                title: '我的藏品',
            }
        },
        {
            path: '/mygoods-info',
            name: 'mygoods-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-info'),
            meta: {
                title: '藏品详情',
            }
        },
        {
            path: '/mygoods-log',
            name: 'mygoods-log',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-log'),
            meta: {
                title: '打工日志',
            }
        },
        {
            path: '/mygoods-details',
            name: 'mygoods-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-details'),
            meta: {
                title: '藏品详情',
            }
        },
        {
            path: '/market',
            name: 'market',
            component: () => import(/* webpackChunkName: "about" */ '../pages/market/market-list'),
            meta: {
                title: '市场',
                showTabbar: true

            },
        },
        {
            path: '/market/goods',
            name: 'market_goods',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/market/goods-detail'),
            meta: {
                title: '市场',
            }
        },
        {
            path: '/order/info',
            name: 'orderInfo',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-info'),
            meta: {
                title: '订单详情',
            }
        },
        {
            path: '/notice',
            name: 'notice',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/notice/notice'),
            meta: {
                title: '公告',
                showTabbar: true
            }
        },
        {
            path: '/notice/notice-details',
            name: 'notice-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/notice/notice-details'),
            meta: {
                title: '公告详情',
                showTabbar: false
            }
        },
        {
            path: '/member/set-up',
            name: 'set-up',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/set-up')
            ,
            meta: {
                title: '设置',
                showTabbar: false

            }
        },
        {
            path: '/member/info',
            name: 'info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/info')
            ,
            meta: {
                title: '个人信息',
                showTabbar: false

            }
        },
        {
            path: '/member/edit-password',
            name: 'edit-password',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-password')
            ,
            meta: {
                title: '修改密码',
                showTabbar: false
            }
        },
        {
            path: '/member/edit-paypassword',
            name: 'edit-paypassword',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-paypassword')
            ,
            meta: {
                title: '修改支付密码',
                showTabbar: false
            }
        },
        {
            path: '/member/edit-info',
            name: 'edit-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-info')
            ,
            meta: {
                title: '修改信息',
                showTabbar: false

            }
        },
        {
            path: '/member/forgot-password',
            name: 'forgot-password',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/forgot-password')
            ,
            meta: {
                title: '忘记密码',
                showTabbar: false

            }
        },
        {
            path: '/invite',
            name: 'invite',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/invite')
            ,
            meta: {
                title: '邀请好友',
                showTabbar: false
            }
        },
        {
            path: '/invite-list',
            name: 'invite-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/invite-list')
            ,
            meta: {
                title: '邀请记录',
                showTabbar: false
            }
        },
        {
            path: '/ranking',
            name: 'ranking',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/ranking')
            ,
            meta: {
                title: '邀请排行榜',
                showTabbar: false
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/register/register'),
            meta: {
                title: '注册账号',
                showTabbar: false

            }
        },
        {
            path: '/order-list',
            name: 'order-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-list')
            ,
            meta: {
                title: '订单列表',
                showTabbar: false
            }
        },
        {
            path: '/order-details',
            name: 'order-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-details')
            ,
            meta: {
                title: '订单详情',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-list',
            name: 'resale-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-list')
            ,
            meta: {
                title: '我的转售',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-info',
            name: 'resale-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-info')
            ,
            meta: {
                title: '转售藏品',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-details',
            name: 'resale-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-details')
            ,
            meta: {
                title: '转售订单详情',
                showTabbar: false
            }
        }, 
        {
            path: '/transfer/order-list',
            name: 'transfer-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/transfer/order-list')
            ,
            meta: {
                title: '我的转赠',
                showTabbar: false
            }
        },
        {
            path: '/transfer/order-details',
            name: 'transfer-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/transfer/order-details')
            ,
            meta: {
                title: '转赠订单详情',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/list',
            name: 'synthesis-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/list')
            ,
            meta: {
                title: '合成藏品活动',
                showTabbar: false
            }
        },
        {
            path: '/synthesis/details',
            name: 'synthesis-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/details')
            ,
            meta: {
                title: '合成详情',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/order-list',
            name: 'synthesis-order',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/order-list')
            ,
            meta: {
                title: '合成记录',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/order-details',
            name: 'synthesis-order-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/order-details')
            ,
            meta: {
                title: '合成记录详情',
                showTabbar: false
            }
        }, 
        {
            path: '/lucyk/grid',
            name: 'grid',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/lucyk/grid')
            ,
            meta: {
                title: '幸运抽奖',
                showTabbar: false
            }
        },
        {
            path: '/login/agreement',
            name: 'agreement',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/login/agreement')
            ,
            meta: {
                title: '协议',
                showTabbar: false
            }
        }, 
        {
            path: '/shop',
            name: 'shop',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/index/index')
            ,
            meta: {
                title: '生活',
                showTabbar: false
            }
        },
        {
            path: '/category',
            name: 'category',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/goods/category')
            ,
            meta: {
                title: '全部分类',
                showTabbar: false
            }
        },
        {
            path: '/search',
            name: 'search',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/goods/search')
            ,
            meta: {
                title: '搜索商品',
                showTabbar: false
            }
        }, 
        {
            path: '/goodslist',
            name: 'goodslist',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/goods/list')
            ,
            meta: {
                title: '全部商品',
                showTabbar: false
            }
        }, 
        {
            path: '/goodsdetail',
            name: 'goodsdetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/goods/detail')
            ,
            meta: {
                title: '商品详情',
                showTabbar: false
            }
        }, 
        {
            path: '/confirm',
            name: 'confirm',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/confirm/detail')
            ,
            meta: {
                title: '确认订单',
                showTabbar: false
            }
        },
        {
            path: '/addresslist',
            name: 'addresslist',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/user/address/list')
            ,
            meta: {
                title: '收货地址',
                showTabbar: false
            }
        },
        {
            path: '/addressedit',
            name: 'addressedit',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/user/address/edit')
            ,
            meta: {
                title: '添加收货',
                showTabbar: false
            }
        },
        {
            path: '/shoporderlist',
            name: 'shoporderlist',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/order/list')
            ,
            meta: {
                title: '订单列表',
                showTabbar: false
            }
        },
        {
            path: '/shoporderdetail',
            name: 'shoporderdetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/order/detail')
            ,
            meta: {
                title: '订单详情',
                showTabbar: false
            }
        },
        {
            path: '/shoporderresult',
            name: 'shoporderresult',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/order/result')
            ,
            meta: {
                title: '支付成功',
                showTabbar: true
            }
        },
        {
            path: '/article',
            name: 'article',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/article/list')
            ,
            meta: {
                title: '文章列表',
                showTabbar: true
            }
        },
        {
            path: '/articledetail',
            name: 'articledetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/article/detail')
            ,
            meta: {
                title: '文章详情',
                showTabbar: false
            }
        },
        {
            path: '/form',
            name: 'form',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/form/detail')
            ,
            meta: {
                title: '表单',
                showTabbar: false
            }
        },
        {
            path: '/storeinfo',
            name: 'storeinfo',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/store/index')
            ,
            meta: {
                title: '商家',
                showTabbar: false
            }
        }, 
        {
            path: '/infos',
            name: 'infos',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/store/info')
            ,
            meta: {
                title: '商家详情',
                showTabbar: false
            }
        }, 
        {
            path: '/evaluate',
            name: 'evaluate',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/evaluate/index')
            ,
            meta: {
                title: '商品评价',
                showTabbar: false
            }
        }, 
        {
            path: '/evaluatedetail',
            name: 'evaluatedetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/evaluate/detail')
            ,
            meta: {
                title: '评价商品',
                showTabbar: false
            }
        },
        {
            path: '/express',
            name: 'express',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/express/index')
            ,
            meta: {
                title: '配送信息',
                showTabbar: false
            }
        }, 
        {
            path: '/commission',
            name: 'commission',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/commission/index')
            ,
            meta: {
                title: '佣金明细',
                showTabbar: false
            }
        }, 
        {
            path: '/coin',
            name: 'coin',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/coin/index')
            ,
            meta: {
                title: '金币明细',
                showTabbar: false
            }
        }, 
        {
            path: '/withdraw',
            name: 'withdraw',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/withdraw/index')
            ,
            meta: {
                title: '提现',
                showTabbar: false
            }
        }, 
        {
            path: '/withdrawdetail',
            name: 'withdrawdetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/withdraw/detail')
            ,
            meta: {
                title: '提现详情',
                showTabbar: false
            }
        }, 
        {
            path: '/withdrawlist',
            name: 'withdrawlist',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/withdraw/list')
            ,
            meta: {
                title: '提现记录',
                showTabbar: false
            }
        }, 
        {
            path: '/collect',
            name: 'collect',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/collect/list')
            ,
            meta: {
                title: '我的收藏',
                showTabbar: false
            }
        },
        {
            path: '/storesystem',
            name: 'storesystem',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/storesystem/index')
            ,
            meta: {
                title: '结算记录',
                showTabbar: false
            }
        },
        {
            path: '/storecenter',
            name: 'storecenter',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/storesystem/center')
            ,
            meta: {
                title: '商家中心',
                showTabbar: false
            }
        },
        {
            path: '/circle',
            name: 'circle',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/circle/list')
            ,
            meta: {
                title: '圈子',
                showTabbar: false
            }
        },
        {
            path: '/circledetail',
            name: 'circledetail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/circle/detail')
            ,
            meta: {
                title: '发布圈子',
                showTabbar: false
            }
        },
        {
            path: '/storecircle',
            name: 'storecircle',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/store/circle')
            ,
            meta: {
                title: '圈子',
                showTabbar: false
            }
        },
        {
            path: '/chats',
            name: 'chats',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/chats/index')
            ,
            meta: {
                title: '客服聊天',
                showTabbar: false
            }
        }, 
        {
            path: '/chatslist',
            name: 'chatslist',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/chats/list')
            ,
            meta: {
                title: '客服聊天',
                showTabbar: false
            }
        },
        {
            path: '/userschats',
            name: 'userschats',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/shop/chats/userschats')
            ,
            meta: {
                title: '客服聊天',
                showTabbar: false
            }
        },
    ]
};

export default routerConfig;
