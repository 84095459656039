import Vue from 'vue'
import VueRouter from 'vue-router'
import routerConfig from './routerConfig'
Vue.use(VueRouter)
let router = new VueRouter({
    mode: 'hash', // app 打开需要使用 hash / history
    routes: routerConfig.routes
});

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router
