
export const getCacheUserinfo = () => {
    let userinfo = localStorage.getItem('uinfo');
    if (userinfo) {
        userinfo = JSON.parse(userinfo);
        return userinfo;
    } else {
        return null;
    }
};

export const getCacheUserToken = () => {
    return localStorage.getItem("jtoken")
}

export const setCacheUserInfo = (userinfo) => {
    localStorage.setItem('uinfo', JSON.stringify(userinfo))
}

export const delCacheUserInfo = () => {
    localStorage.clear();
}

export const timestampToTime = (value, type = 0) => {
    var time = new Date(value);
    var year = time.getFullYear();
    var month = time.getMonth() + 1;
    var date = time.getDate();
    var hour = time.getHours();
    var minute = time.getMinutes();
    var second = time.getSeconds();
    month = month < 10 ? "0" + month : month;
    date = date < 10 ? "0" + date : date;
    hour = hour < 10 ? "0" + hour : hour;
    minute = minute < 10 ? "0" + minute : minute;
    second = second < 10 ? "0" + second : second;
    var arr = [
        year + "-" + month + "-" + date,
        year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second,
        year + "年" + month + "月" + date,
        year + "年" + month + "月" + date + " " + hour + ":" + minute + ":" + second,
        hour + ":" + minute + ":" + second,
        year + "-" + month,
    ]
    return arr[type];
} 